a {
  cursor: pointer;
}

html {
  overflow-x: hidden;
}

body {
  background-image: url("./img/background-v2.jpg");
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;
  /* Resize the background image to cover the entire container */
  background-color: #CFE9F8;
  /* background-image: linear-gradient(#CFE9F8, #a3dbfc); */
}

html,
body,
#root,
.wrapper {
  height: 100%;
}

.App-header {
  background: #CFE9F8 !important;
  background-image: linear-gradient(#CFE9F8, #a3dbfc) !important;
  width: 100vw !important;
  padding: 0em !important;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.navbar {
  position: sticky;
  top: 0;
  z-index: 999;
}

.pageContainer {
  flex: 1 0 auto;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.pageSegment, .pageSegment .segment {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
}

.pageSegment:not(.inverted) .header.center {
  background-color: rgba(255, 255, 255, 0.90) !important;
  color: #2185d0 !important;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border: #2185d0 1px solid;
  border-radius: 0.28571429rem;
}

.pageSegment:not(.inverted) img {
  border: white 1px solid !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.modal, .modal .content {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
}

.ui.modal>.close {
  top: 0;
}

.carousel-back, .carousel-next {
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  outline: inherit;
}

.footer {
  flex-shrink: 0;
  padding: 5em 0em !important;
}

.hidden {
  display: none;
}